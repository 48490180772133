<template>
    <vue-draggable-resizable @activated="selectBox" :h="400" :w="300">
        <v-toolbar dense>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer />
            <v-btn
                v-if="canClose"
                @click="close"
                icon
                small
                title="Close">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <!-- style="opacity: 0.97" -->
        <v-card
            height="100%"
            width="100%"
            class="overflow-y-auto">
            <v-text-field
                flat
                dense
                hide-details
                hide-no-data
                solo-inverted
                small
                single-line
                append-icon="mdi-close"
                label="Find"
                v-model="searchInput"
                @click:append="searchInput = null" />
            <drop @drop="insertItem" style="height: 100%">
                <v-card-text class="pa-0" full-width>
                    <v-list dense>
                        <v-list-item-group>
                            <template v-for="item in filteredItems">
                                <drag
                                    :key="item.id"
                                    :data="item">
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ getNestedValue(item.manyItem, manyText) }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon small @click.stop="remove(item)" :loading="item.loadingMsg != null">
                                                <v-icon class="error--text" small>mdi-close</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </drag>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </drop>
            <v-overlay :value="loadingMsg != null" absolute class="text-center">
                <v-progress-circular indeterminate size="64" />
                <p>{{ loadingMsg }}</p>
            </v-overlay>
            <v-overlay :value="error != null" absolute class="text-center">
                <v-progress-circular indeterminate size="64" />
                <p>{{ error }}</p>
            </v-overlay>
        </v-card>
    </vue-draggable-resizable>
</template>

<script>
import { firstBy } from 'thenby';
import { Drag, Drop } from "vue-easy-dnd";

export default {
    name: 'BT-Board-Drop-List',
    components: {
        VueDraggableResizable: () => import('vue-draggable-resizable'),
        Drag,
        Drop
    },
    data: function() {
        return {
            allLinks: [],
            asyncItems: [],
            error: null,
            loadingMsg: null,
            msg: null,
            searchInput: null
        }
    },
    async mounted() {
        await this.pullItems();
    },
    props: {
        canClose: {
            type: Boolean,
            default: true
        },
        item: null,

        itemProperties: {
            type: Array,
            default: null
        },
        linkNavigation: {
            type: String,
            default: null
        },
        linkGetURL: {
            type: String,
            default: '/get/Links/{id}' //{id}
        },
        linkParams: {
            type: Object,
            default: null
        },
        linkPostURL: {
            type: String,
            default: '/post/Links/Add'
        },
        linkRemoveByDelete: { //when removing a link - whether to post or delete
            type: Boolean,
            default: false
        },
        linkRemoveURL: {
            type: String,
            default: '/post/Links/Remove'
        },
        manyNavigation: {
            type: String,
            default: null
        },
        manyProp: {
            type: String,
            default: null
        },
        manyText: {
            type: String,
            default: 'text'
        },
        manyToMany: {
            type: Boolean,
            default: false
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        searchProps: {
            type: Array,
            default: null
        },
        // singleID: { //order slot id value
        //     type: String,
        //     default: null
        // },
        singleProp: { //order slot prop name in link or many item
            type: String,
            default: null
        },
        sortProp: {
            type: String,
            default: null
        },
        titleProp: {
            type: String,
            default: null
        },
    },
    watch: {
        item: function(oldVal, newVal) {
            if (newVal != null && oldVal != newVal) {
                this.pullItems();
            }
        }
    },
    computed: {
        filteredItems() {
            var r = this.asyncItems;

            if (this.searchInput != null) {
                r = r.filter(x => this.hasSearch(x.manyItem, this.searchInput, this.searchProps));
            }

            if (this.manyToMany) {
                r = r.filter(z => this.allLinks.some(lnk => lnk[this.manyProp] == z.manyItem.id ));
            }
            else {
                var singleID = this.item.id;
                r = r.filter(z => z.manyItem[this.singleProp] == singleID);
            }
            
            if (this.sortProp != null) {
                r.sort(firstBy(z => this.getNestedValue(z.manyItem, this.sortProp)));
            }
            
            return r;
        },
        title() {
            if (this.item == null || this.titleProp == null) {
                return null;
            }

            return this.item[this.titleProp];
        }
    },
    methods: {
        close() {
            this.$emit('close', {
                id: this.item.id,
                singleProp: this.singleProp
            });
        },
        getParamObj() {
            var paramObj = {};

            if (this.isLengthyArray(this.itemProperties)) {
                paramObj.properties = this.copyDeep(this.itemProperties).toString();
            }

            return paramObj;
        },
        async insertItem(dat) {
            if (dat == null || dat.data == null) {
                return;
            }

            var d = dat.data;

            try {
                this.loadingMsg = 'Loading';
                d.loadingMsg = 'Loading';

                if (this.manyToMany) {
                    //add link
                    var link = { id: null };

                    link[this.manyProp] = d.manyItem.id;
                    link[this.singleProp] = this.item.id;

                    await this.$BlitzIt.api.post(this.linkNavigation, link, null, this.linkPostURL);
                    
                    this.allLinks.push(link);
                    d.links.push(link);
                }
                else {
                    //patch manyProp of manyItem
                    d.manyItem[this.singleProp] = this.item.id;
                    var patch = { id: d.manyItem.id };
                    patch[this.singleProp] = this.item.id;

                    await this.$BlitzIt.store.partialPatch(this.manyNavigation, patch, null);

                }
            }
            catch (err) {
                this.error = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
                d.loadingMsg = null;
            }
        },
        async pullItems(refresh = false) {
            try {
                this.loadingMsg = 'Loading';

                if (this.manyToMany) {
                    //links
                    var getURL = this.linkGetURL;
                    if (getURL != null) {
                        //getURL = getURL.replace('{id}', `${this.singleID}`);
                        getURL = getURL.replace('{id}', `${this.item.id}`);
                    }
                 
                    //get relevant links
                    this.allLinks = await this.$BlitzIt.store.getAll(this.linkNavigation, null, refresh, null, getURL);
                }

                var paramObj = this.getParamObj();

                var res = await this.$BlitzIt.store.getAll(this.manyNavigation, paramObj, refresh, null, null);

                res = res.map(x => Object.assign({}, {
                    manyItem: x,
                    links: this.allLinks,
                    loadingMsg: null,
                    errorMsg: null,
                    singleID: this.item.id
                }));

                if (this.onPullSuccessAsync != null) {
                    res = await this.onPullSuccessAsync(res, refresh);
                }

                this.asyncItems = res;
            }
            catch (err) {
                this.error = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async remove(item) {
            try {
                item.loadingMsg = 'Removing';

                if (this.manyToMany) {
                    //remove link
                    var linkIndex = this.allLinks.findIndex(z => z[this.singleProp] == this.item.id && z[this.manyProp] == item.manyItem.id);
                    if (linkIndex >= 0) {
                        if (this.linkRemoveByDelete) {
                            await this.$BlitzIt.api.delete(this.linkNavigation, this.allLinks[linkIndex], null, this.linkRemoveURL);
                        }
                        else {
                            await this.$BlitzIt.api.post(this.linkNavigation, this.allLinks[linkIndex], null, this.linkRemoveURL);
                        }
                    }

                    this.allLinks.splice(linkIndex, 1);

                    this.$emit('removeLink', { manyNavigation: this.manyNavigation, manyID: item.manyItem.id, singleID: this.item.id });
                }
                else {
                    item.manyItem[this.singleProp] = null;
                    var patch = { id: item.manyItem.id };
                    patch[this.singleProp] = null;

                    await this.$BlitzIt.store.partialPatch(this.manyNavigation, patch, null);
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingMsg = null;
            }
        },
        selectBox() {
            this.$emit('select', {
                items: this.asyncItems,
                linkNavigation: this.linkNavigation,
                linkGetURL: this.linkGetURL,
                linkParams: this.linkParams,
                linkPostURL: this.linkPostURL,
                linkRemoveByDelete: this.linkRemoveByDelete,
                linkRemoveURL: this.linkRemoveURL,
                manyNavigation: this.manyNavigation,
                manyProp: this.manyProp,
                manyText: this.manyText,
                manyToMany: this.manyToMany,
                singleProp: this.singleProp,
                singleID: this.item != null ? this.item.id : null,
            });
        }
    }
}
</script>